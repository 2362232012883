<template>
  <BaseSidebar v-model="open" size-class="w-64">
    <template #header>NFTs</template>
    <template #default>
      <div class="px-3">
        <div class="mb-2">
          <DropdownBlockchain @select="changeOption($event)" :supported-chains="supportedChains"> </DropdownBlockchain>
        </div>
        <BaseSearchbox
          :placeholder="'Search Collection (' + titleizedChainName + ')'"
          v-model="search"
          @keyup.enter="onEnter"
        />
      </div>
      <BaseNavRadio v-if="search.length === 0" v-model="selectedPage">
        <template #default>
          <BaseNavRadioOption v-for="(option, index) in pages" :key="index" :option="option">
            {{ option.label }}
          </BaseNavRadioOption>
        </template>
      </BaseNavRadio>
      <div v-else-if="loading" class="flex items-center justify-center pt-10">
        <BaseLoader />
      </div>
      <div
        v-else-if="results.length === 0"
        class="mt-16 h-full overflow-y-scroll px-6 py-2 text-center text-sm text-gray-400"
      >
        Please enter a valid contract address or name and press Enter
      </div>
      <div v-else class="pt-1">
        <InertiaLink
          v-for="result in searchResults"
          :key="result.address"
          class="flex items-center px-4 py-2"
          :href="`/nft/collections/${result.address}?chain=${nftChain}`"
          @click="clearSearch"
        >
          <img :src="result.image_url" class="h-4 w-4 rounded-full bg-gray-800" loading="lazy" />
          <div class="pl-2 pr-1 text-xs font-medium">{{ result.collection_name }}</div>
          <div
            @click="
              $event.preventDefault();
              onCollectionSaved(result);
            "
            class="cursor-pointer"
          ></div>
        </InertiaLink>
      </div>
    </template>
  </BaseSidebar>
</template>

<script setup>
import { useStore } from 'vuex';
import { router, Link as InertiaLink } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3';
import { useGetSidebarStatus } from '@/composeables/sidebar';
import { ref, computed, onMounted, watch, inject } from 'vue';
import DropdownBlockchain from '@/components/dropdown/DropdownBlockchain.vue';
import { titleize } from '@/composeables/filters';
import debounce from 'lodash/debounce';
import { useLocalStorage } from '@vueuse/core';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';

const $http = useHttp();
const $store = useStore();
const page = usePage();
const $emitter = useEmitter();

// USER COLLECTION
const addUserCollection = inject('addUserCollection');
const userCollections = inject('userCollections');

const onCollectionSaved = col => {
  addUserCollection({
    name: col.collection_name,
    contract: col.address
  });
};

// PAGES
const selectedPageSet = ref(false);
const selectedPage = ref(null);
const nftSelectedPage = useLocalStorage('nftSelectedPage', null);

const pages = computed(() => {
  const data = [
    { label: 'Market', id: 'market', href: '/nft' },
    { label: 'Trending', id: 'trending', href: '/nft/trending' },
    { label: 'Collections', id: 'collections', href: '/nft/collections' }
  ];
  return data;
});

watch(selectedPage, () => {
  if (selectedPageSet.value && selectedPage.value) {
    router.visit(selectedPage.value.href);
    nftSelectedPage.value = selectedPage.value.id;
  }
  selectedPageSet.value = true;
});

const urlSlug = computed(() => {
  return page.url.split('/').at(-1);
});

watch(urlSlug, () => {
  if (urlSlug.value.length && !pages.value.map(x => x.href.split('/').at(-1)).includes(urlSlug.value)) {
    selectedPage.value = null;
  }
});

onMounted(async () => {
  const route = page.url;
  if (route.includes('collections')) {
    if (urlSlug.value.length && urlSlug.value !== 'collections') selectedPageSet.value = true;
    else selectedPage.value = pages.value.find(p => p.id === 'collections');
  } else if (route.includes('trending')) {
    selectedPage.value = pages.value.find(p => p.id === 'trending');
  } else {
    selectedPage.value = pages.value.find(p => p.id === 'market');
  }
});

// CHAINS
const supportedChains = computed(() => {
  return $store.state.supportedChains.filter(chain => chain.id !== 'bitcoin').map(chain => chain.id);
});

// SEARCH
const { open } = useGetSidebarStatus('nfts');
const search = ref('');
const results = ref([]);
const searchIndex = ref(0);
const loading = ref(false);
const contract = ref('');
const selectedChainOption = ref('ethereum');

const searchResults = computed(() => {
  const collections = (userCollections.value || []).map(col => {
    return col.contract.toLowerCase();
  });

  return results.value.map(result => {
    return {
      ...result,
      saved: collections.includes(result.address.toLowerCase())
    };
  });
});

const debouncedSearch = debounce(async () => {
  if (search.value.length >= 3) {
    loading.value = true;
    searchIndex.value++;
    const searchIndexBefore = searchIndex.value;
    const res = await $http.get('/data/nft', {
      params: {
        v: 2,
        method: 'collection/search',
        params: {
          search: search.value,
          chain: selectedChainOption.value
        }
      }
    });

    if (searchIndex.value === searchIndexBefore) {
      results.value = res.data;
      loading.value = false;
    }
  }
}, 1000);

watch(search, debouncedSearch);

const clearSearch = () => {
  search.value = '';
  results.value = [];
  loading.value = false;
};

// NFT CHAIN SELECTION
const nftChain = useLocalStorage('nftChain', 'ethereum');
const titleizedChainName = computed(() => {
  return titleize(nftChain.value);
});

watch(nftChain, () => {
  search.value = '';
  results.value = [];
  loading.value = false;
});

const changeOption = id => {
  if (selectedChainOption.value !== id) {
    $emitter.$emit('select:chain', id);
    selectedChainOption.value = id;
    // Apply the chain query param to the current URL
    const url = new URL(window.location.href);
    // url.searchParams.set('chain', id);
    router.visit(url.toString());
  }
};

const onEnter = () => {
  if (search.value) {
    // check if eth address
    if (search.value.length === 42 && search.value[0] === '0' && search.value[1].toLowerCase() === 'x') {
      router.visit(`/nft/collections/${search.value}?chain=${selectedChainOption.value}`);
    } else if (results.value.length > 0) {
      router.visit(`/nft/collections/${results.value[0].address}?chain=${selectedChainOption.value}`);
    } else {
      router.visit(`/nft/collections/${search.value}?chain=${selectedChainOption.value}`);
    }
  }
};
</script>
